// Browser & OS & Render Detect
// Browsers
var chrome = /chrome/.test(navigator.userAgent.toLowerCase());
var safari = /safari/.test(navigator.userAgent.toLowerCase());
var firefox = /firefox/.test(navigator.userAgent.toLowerCase());
var opera = /opr/.test(navigator.userAgent.toLowerCase());
var msie = /msie/.test(navigator.userAgent.toLowerCase()) || /trident/.test(navigator.userAgent.toLowerCase()) || /.net/.test(navigator.userAgent.toLowerCase());
var edge = /edge/.test(navigator.userAgent.toLowerCase());
var yandex = /yabrowser/.test(navigator.userAgent.toLowerCase());
// OS
var windows = /win/.test(navigator.platform.toLowerCase());
var mac = /mac/.test(navigator.platform.toLowerCase());
var linux = /linux/.test(navigator.platform.toLowerCase());
var ios = /iphone|ipod|ipad/.test(navigator.platform.toLowerCase());
var android = /android/.test(navigator.platform.toLowerCase());
var windowsPhone = /windows phone/.test(navigator.platform.toLowerCase());
// Render engines
var isWebkit = /applewebkit/.test(navigator.platform.toLowerCase());
var isGecko = /gecko\//.test(navigator.platform.toLowerCase());
var isTrident = /trident/.test(navigator.platform.toLowerCase());
if (yandex) {
  var browser = 'yandex';
  safari = false;
  chrome = false;
} else if (opera) {
  var browser = 'opera';
  safari = false;
  chrome = false;
} else if (edge) {
  var browser = 'edge';
  safari = false;
  chrome = false;
} else if (chrome) {
  var browser = 'chrome';
  safari = false;
} else if (safari) {
  var browser = 'safari';
} else if (firefox) {
  var browser = 'firefox';
} else if (msie) {
  var browser = 'ie';
}
if (windows) {
  var os = 'windows';
} else if (mac) {
  var os = 'mac';
} else if (linux) {
  var os = 'linux';
} else if (ios) {
  var os = 'ios';
} else if (android) {
  var os = 'android';
} else if (windowsPhone) {
  var os = 'windowsPhone';
}
if (isWebkit) {
  var render = 'webkit';
} else if (isGecko) {
  var render = 'gecko';
} else if (isTrident) {
  var render = 'trident';
} else {
  var render = "";
}

function addHtmlClass(browserClass, osClass, renderClass) {
  "use strict";
  var htmlElt = document.getElementsByTagName("body")[0];
  if (htmlElt.className.indexOf(browserClass) === -1) {
    htmlElt.className = htmlElt.className + " " + osClass + " " + browserClass + " " + renderClass;
  }
}
addHtmlClass(browser, os, render);


var cardslider = $('.my-slider').cardslider({
  swipe: true,
  dots: false,
  loop: true,
  nav: true,
  direction: 'left',
}).data('cardslider');

// $('.skiplink').on('click', function(e) {
//   e.preventDefault();
//   cardslider.changeCardTo('last');
// });
